export const Logo = (): JSX.Element => {
  return (
    <div
      style={{
        color: "#000",
      }}
      className="logo"
    >
      <a href="/">
        <h1
          style={{
            color: "#000",
            display: "inline",
            fontStyle: "italic",
            fontWeight: 700,
          }}
        >
          selskab.gl
        </h1>
      </a>{" "}
      - en del af{" "}
      <a
        href="https://nuna-law.gl"
        target="_blank"
        style={{ fontWeight: "bold", color: "#000", textDecoration: "none" }}
        rel="noreferrer"
      >
        Nuna Advokater
      </a>
    </div>
  );
};
