import React, { useCallback, useMemo, useState } from "react";
import {
  Stack,
  Text,
  FontWeights,
  IStackTokens,
  IStackStyles,
  ITextStyles,
  DefaultButton,
  SearchBox,
  DatePicker,
  IDatePickerStrings,
} from "@fluentui/react";
import "./App.css";
import useAxios from "axios-hooks";
import { Logo } from "./logo";
import _ from "lodash";
import moment from "moment";
import { Box } from "@mui/system";
import {
  AppBar,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  Toolbar,
} from "@mui/material";

const boldStyle: Partial<ITextStyles> = {
  root: { fontWeight: FontWeights.semibold },
};
const stackTokens: IStackTokens = { childrenGap: 15 };
const stackStyles: Partial<IStackStyles> = {
  root: {
    width: "100%",
    margin: "0 auto",
    textAlign: "center",
    color: "#605e5c",
  },
};

export const App: React.FunctionComponent = () => {
  const [take] = useState(10);
  const [skip, setSkip] = useState(0);
  const [search, setSearch] = useState("");
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [onlyCorp, setOnlyCorp] = useState(true);

  const [{ data, loading }, refetch] = useAxios<{
    count: number;
    statuses: {
      id: number;
      oldValue: string;
      newValue: string;
      type: "STATUS" | "NAME" | "ADDRESS" | "NOOWNER";
      company: { name: string };
      companyId: number;
      updatedAt: string;
    }[];
  }>(
    `/api/status?take=${take}&skip=${skip}&search=${encodeURIComponent(
      search
    )}&fromDate=${fromDate}&toDate=${toDate}${onlyCorp ? "&onlyCorp=true" : ""}`
  );

  const handleNextClick = useCallback(() => {
    setSkip(skip + take);
    refetch();
    window.scrollTo(0, 0);
  }, [refetch, skip, take]);
  const handlePrevClick = useCallback(() => {
    setSkip(skip - take);
    refetch();
    window.scrollTo(0, 0);
  }, [refetch, skip, take]);

  const onSearch = useCallback(
    (searchVal: string) => {
      setSearch(searchVal);
      setSkip(0);
      refetch();
    },
    [refetch]
  );
  console.log(search);
  const dayPickerStrings: IDatePickerStrings = {
    months: [
      "Januar",
      "Februar",
      "Marts",
      "April",
      "MaJ",
      "Juni",
      "July",
      "August",
      "September",
      "Oktober",
      "November",
      "December",
    ],

    shortMonths: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Maj",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Dec",
    ],

    days: [
      "Søndag",
      "Mandag",
      "Tirsdag",
      "Onsdag",
      "Torsdag",
      "Fredag",
      "Lørdag",
    ],

    shortDays: ["S", "M", "T", "O", "T", "F", "L"],

    goToToday: "Go til idag",
    prevMonthAriaLabel: "Go til forrige måned",
    nextMonthAriaLabel: "Go til næste måned",
    prevYearAriaLabel: "Go til forrige år",
    nextYearAriaLabel: "Go til næste år",
  };
  const onFromDateChange = useCallback(
    (date) => setFromDate(moment(date).format("YYYY-MM-DD")),
    []
  );
  const onToDateChange = useCallback(
    (date) => setToDate(moment(date).format("YYYY-MM-DD")),
    []
  );

  const formatDate = useCallback((date) => {
    return moment(date).format("DD / MM / YYYY");
  }, []);

  const handleOnlyCorpChange = useCallback(
    (event) => {
      setOnlyCorp(event.target.checked);
      setSkip(0);
      refetch();
    },
    [refetch]
  );

  return (
    <div style={{ fontFamily: "IBM Plex Sans,system" }}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar style={{ backgroundColor: "#FFF" }}>
            <Logo />
          </Toolbar>
        </AppBar>
      </Box>
      <Grid container style={{ maxWidth: 1280, margin: "auto" }}>
        <Grid item sm={3}>
          <Paper square style={{ padding: "1em", margin: "1em" }}>
            <div style={{ fontWeight: "bold", fontSize: 24 }}>Filter</div>
            <SearchBox
              placeholder="Søg"
              styles={{ root: { width: "100%" } }}
              onSearch={onSearch}
            />
            <FormControlLabel
              control={
                <Switch checked={onlyCorp} onChange={handleOnlyCorpChange} />
              }
              label="Kun selskaber"
            />
            <DatePicker
              styles={{ textField: { width: "12em" } }}
              label="Fra dato "
              ariaLabel="Fra dato"
              strings={dayPickerStrings}
              value={fromDate ? new Date(fromDate) : undefined}
              onSelectDate={onFromDateChange}
              formatDate={formatDate}
            />
            <DatePicker
              styles={{ textField: { width: "12em" } }}
              label="Til dato"
              ariaLabel="Til dato"
              strings={dayPickerStrings}
              value={toDate ? new Date(toDate) : undefined}
              onSelectDate={onToDateChange}
              formatDate={formatDate}
            />
          </Paper>
        </Grid>
        <Grid item sm={6}>
          {loading && !data ? (
            <div style={{ padding: "1em", margin: "1em", textAlign: "center" }}>
              <CircularProgress />
            </div>
          ) : (
            data?.statuses.map((update) => (
              <Paper
                square
                key={update.id}
                style={{ padding: "1em", margin: "1em" }}
              >
                <div style={{ fontSize: 16, fontWeight: "bold" }}>
                  {moment(new Date(update.updatedAt)).format("DD/MM/YYYY")}{" "}
                  {update.type === "NAME"
                    ? "Har skiftet navn"
                    : update.type === "STATUS"
                    ? "Har skiftet status"
                    : update.type === "NOOWNER"
                    ? "Har ingen reelle ejere registreret"
                    : "Har skiftet adresse"}
                </div>
                <div style={{ fontSize: 16 }}>
                  <span style={{ fontWeight: "bold" }}>
                    {update.company.name}
                  </span>
                </div>
                <div style={{ fontSize: 16 }}>
                  <span style={{ fontWeight: "bold" }}>CVR-nr: </span>
                  {update.companyId}
                </div>
                <br />
                {update.type === "NAME" ? (
                  <span>
                    Har skiftet navn fra "{update.oldValue}" til "
                    {update.newValue}"
                  </span>
                ) : update.type === "STATUS" ? (
                  <span>
                    Har skiftet status fra "{update.oldValue}" til "
                    {update.newValue}"
                  </span>
                ) : update.type === "NOOWNER" ? (
                  <span>Har ingen reelle ejere registreret</span>
                ) : (
                  <span>
                    Har skiftet adresse fra "{update.oldValue}" til "
                    {update.newValue}"
                  </span>
                )}
              </Paper>
            ))
          )}
          <Stack horizontal tokens={stackTokens} horizontalAlign="center">
            <DefaultButton
              disabled={!skip}
              text="Forrige"
              onClick={handlePrevClick}
            />
            <DefaultButton
              text="Næste"
              onClick={handleNextClick}
              disabled={skip + take >= (data?.count || 0)}
            />
          </Stack>
          <br />
        </Grid>
        <Grid item sm={3}></Grid>
      </Grid>
    </div>
  );
  /*  return (
    <Stack
      horizontalAlign="center"
      verticalAlign="start"
      verticalFill
      styles={stackStyles}
      tokens={stackTokens}
    >
      <div style={{ backgroundColor: "#dfdfdf", width: "100%" }}>
        <div
          style={{
            backgroundColor: "#3b3b3b",
            width: "30em",
            padding: 5,
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Logo />
        </div>
      </div>
      <Text variant="large">CVR Opdateringer</Text>
      <div
        style={{
          width: "30em",
        }}
      >
        <SearchBox
          placeholder="Søg"
          styles={{ root: { width: "100%" } }}
          onSearch={onSearch}
        />
      </div>
      <Stack horizontal tokens={stackTokens} horizontalAlign="center">
        <DatePicker
          styles={{ textField: { width: "12em" } }}
          label="Fra dato "
          ariaLabel="Fra dato"
          strings={dayPickerStrings}
          value={fromDate ? new Date(fromDate) : undefined}
          onSelectDate={onFromDateChange}
          formatDate={formatDate}
        />
        <DatePicker
          styles={{ textField: { width: "12em" } }}
          label="Til dato"
          ariaLabel="Til dato"
          strings={dayPickerStrings}
          value={toDate ? new Date(toDate) : undefined}
          onSelectDate={onToDateChange}
          formatDate={formatDate}
        />
      </Stack>
      {loading && !groupedData
        ? "Loading..."
        : groupedData?.map((updates) => {
            return (
              <>
                <Text variant="large" key={updates.date} styles={boldStyle}>
                  {updates.date}
                </Text>
                {updates.updates.map((update) => (
                  <div
                    key={update.id}
                    style={{
                      borderBottom: "1px solid #000",
                      paddingBottom: "0.5em",
                      width: "30em",
                    }}
                  >
                    <Stack
                      horizontal
                      tokens={stackTokens}
                      horizontalAlign="start"
                    >
                      <Stack
                        horizontalAlign="start"
                        verticalAlign="start"
                        verticalFill
                        styles={{ root: { width: "10em", textAlign: "left" } }}
                      >
                        <Text variant="medium" styles={boldStyle}>
                          {update.company.name}
                        </Text>
                        <Text variant="small">{update.companyId}</Text>
                      </Stack>
                      {update.type === "NAME" ? (
                        <Text variant="medium">
                          Har skiftet navn fra "{update.oldValue}" til "
                          {update.newValue}"
                        </Text>
                      ) : update.type === "STATUS" ? (
                        <Text variant="medium">
                          Har skiftet status fra "{update.oldValue}" til "
                          {update.newValue}"
                        </Text>
                      ) : update.type === "NOOWNER" ? (
                        <Text variant="medium">
                          Har ingen reelle ejere registreret
                        </Text>
                      ) : (
                        <Text variant="medium">
                          Har skiftet adresse fra "{update.oldValue}" til "
                          {update.newValue}"
                        </Text>
                      )}
                    </Stack>
                  </div>
                ))}
              </>
            );
          })}
      <Stack horizontal tokens={stackTokens} horizontalAlign="center">
        <DefaultButton
          disabled={!skip}
          text="Previous"
          onClick={handlePrevClick}
        />
        <DefaultButton text="Next" onClick={handleNextClick} />
      </Stack>
    </Stack> 
  );*/
};
